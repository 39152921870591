<template>
  <div class="competitors-finder-page-content">
    <template v-if="!resultsLoading">

      <div class="competitors-wrap">
        <div class="progress-container-wrap">
          <div class="progress-heading">
            <div class="title">
              <div class="text">{{ title }}</div>
            </div>
            <div class="mobile-visible competitors-text">Competitors</div>
            <div class="amount" v-if="competitorsCount !== 0">{{ competitorsCount }} Apps</div>
          </div>
        </div>

        <competitors_tab_apps
          v-if="isCompetitors !== null"
          :key="currentApp.id + currentCountry.code + activeTab + isCompetitors"
          ref="non_competitors_tab_apps"
          :current-app="currentApp"
          :current-country="currentCountry"
          :is-competitors="isCompetitors"
          :competitor-changed-event-from-modal="competitorChangedEventFromModal"
          @competitorsCountChanged="(count) => this.competitorsCount = count"
          @clicked="(item) => openModel(item)"
        ></competitors_tab_apps>
      </div>

    </template>
    <template v-else>
      <progress-indicator :loaded="resultsLoading">{{ loaderText }}</progress-indicator>
    </template>

    <details-modal v-if="showAppDetailsModal"
                       :opened="showAppDetailsModal"
                       :app-data="clickedItem"
                       @competitor-changed="competitorChanged"
                       @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProgressBar from "@/components/UI/ProgressBar";
import ProgressIndicator from "@/components/UI/ProgressIndicator";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import Competitors_tab_apps from "@/views/CompetitorsFinderPage/Competitors/tab_apps.vue";

export default {
  name: "NonCompetitors",
  components: {
    Competitors_tab_apps,
    'progress-indicator': ProgressIndicator,
    ProgressBar,
    DetailsModal,
  },
  data() {
    return {
      activeTab: 'NonCompetitors_tab_apps',
      loaderText: '',
      resultsLoading: false,
      showAppDetailsModal: false,
      competitorsCount: 0,
      clickedItem: {},
      isCompetitors: this.$route.meta.competitor,
      title: this.$route.meta.pageTitle,
      competitorChangedEventFromModal: null,
    }
  },
  created() {
    this.setDocumentTitle();
  },
  methods: {
    competitorChanged(event) {
      this.competitorChangedEventFromModal = event;
    },
    openModel(item) {
      this.clickedItem = item.mutateToModalAppFormat();
      this.showAppDetailsModal = true;
    },
    setDocumentTitle() {
      let title = this.$route.meta.pageTitle;
      if (this.currentApp?.title) {
        title += ' - ' + this.currentApp.title;
      }
      this.setPageTitle(title);
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'currentApp',
      'currentCountry',
      'currentCountryManual',
    ]),
  },
  watch: {
    async $route(to, from) {
      this.title = to?.meta?.pageTitle ?? '';
      this.isCompetitors = this.$route.meta.competitor;
      this.competitorsCount = 0;
    },
    currentApp() {
      this.setDocumentTitle();
    },
  },
}
</script>

<style lang="scss">

</style>