<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator";
import CompetitorBillet from "@/views/CompetitorsFinderPage/components/CompetitorBillet";
import customCompetitors from "@/api/modules/custom-competitors";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import {cropImage, formatNumber, shortenDigits} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {mapGetters} from "vuex";
import {SearchFilterData, SortingData} from "@/api/modules/tables/search-filter-data";
import ChangeCompetitorState from "@/components/Billets/ChangeCompetitorState/index.vue";
import competitorState from "@/api/modules/competitor-state";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {httpRequest} from "@/api";

export default {
  name: "competitors_tab_apps",
  components: {
    PreloaderTableSmall,
    ChangeCompetitorState,
    SvgIcon,
    'progress-indicator': ProgressIndicator,
    'competitor-billet': CompetitorBillet,
    SimpleInfoTable,
  },
  props: {
    currentApp: {
      type: Object,
      default: null,
    },
    currentCountry: {
      type: Object,
      default: null,
    },
    isCompetitors: {
      type: Number,
      default: 1,
    },
    competitorChangedEventFromModal: {
      type: Object,
      default: null,
    }
  },
  emits: [
    'competitors-count-changed',
    'clicked',
  ],
  data() {
    return {
      loaderText: 'Loading competitors...',
      competitorsTabsLoading: false,
      competitorsArray: null,
      competitorsData: new customCompetitors.customCompetitorsViewObject,
      clickedItem: {},
      competitorsCount: 0,
      showAppDetailsModal: false,
      searchFilterData: new SearchFilterData(null, new SortingData('votesCount', 'desc', 'votes_count')),
      isSummaryImpressionsLoaded: false,
    }
  },
  mounted() {
    this.fetchCompetitorsData();
  },
  methods: {
    formatNumber,
    cropImage,
    shortenDigits,
    forceUpdate() {
      this.fetchCompetitorsData();
    },
    async updateCountOnly() {
      //TODO мне не нравится что это дает рассинхрон. Но я пока не знаю как елегантно обойти однонаправленный поток данных vue.
      this.competitorsCount = (await customCompetitors.fetchCompetitors(this.currentApp.id, this.currentCountry.id, this.isCompetitors)).getCompetitors().length;
    },
    async fetchCompetitorsData() {
      this.competitorsTabsLoading = true;
      this.competitorsData = await customCompetitors.fetchCompetitors(this.currentApp.id, this.currentCountry.id, this.isCompetitors);
      this.competitorsCount = this.competitorsData.getCompetitors().length;
      this.competitorsTabsLoading = false;
    },
    async fetchCompetitorsSummaryImpressions() {
      this.isSummaryImpressionsLoaded = false;
      const appIds = this.competitorsData.competitors.map(item => item.competitorId);
      const chunks = [];
      const chunkSize = 20;

      for (let i = 0; i < appIds.length; i += chunkSize) {
        chunks.push(appIds.slice(i, i + chunkSize));
      }

      try {
        const responses = await Promise.all(chunks.map(async (chunk) => {
          const appIdsParams = new URLSearchParams();
          chunk.forEach(id => appIdsParams.append('app_ids[]', id));
          const params = `?country_code=${this.currentCountry.code}&${appIdsParams.toString()}`;

          return httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.reports.GET_SUMMARY_IMPRESSIONS + params);
        }));

        const result = Object.assign({}, ...responses);

        if (Object.keys(result).length > 0) {
          for (let key in this.competitorsData.competitors) {
            const appOriginId = this.competitorsData.competitors[key].competitorOriginId;
            this.competitorsData.competitors[key].impressions = result[appOriginId] !== undefined
                ? result[appOriginId]
                : null;
          }
        }

        this.isSummaryImpressionsLoaded = true;
      } catch (error) {
        this.isSummaryImpressionsLoaded = true;
      }
    },
    async competitorChanged(newType, id, oldType) {
      let competitor = this.competitorsData.competitors.find(item => item.competitorId === id);

      if (competitor) {
        competitor.setState(newType === oldType ? null : newType);
        await competitorState.competitorChanged(newType, id, oldType, this.currentCountry.id)
        this.competitorsCount = this.getLocalCompetitorsNewCount();
      }
    },
    getLocalCompetitorsNewCount() {
      let competitorState;

      if (this.isCompetitors === 1) {
        competitorState = 'competitor';
      } else if (this.isCompetitors === 2) {
        competitorState = 'indirect';
      } else {
        competitorState = 'nonCompetitor';
      }

      return this.competitorsData.getCompetitors().filter(item => item.competitorState === competitorState).length;
    },
    async competitorsItemClickHandler(item) {
      this.clickedItem = {
        id: item.competitorId,
        title: item.title,
        store: item.store,
      };

      this.showAppDetailsModal = true;
    },
    refreshData(searchFilterData) {
      this.searchFilterData = searchFilterData;
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    isNeedCountyFlag(column){
      return column === 'Installs' || column === 'Revenue' || column === 'Impressions';
    },
  },
  computed: {
    ...mapGetters([
      'userSubscription',
    ]),
    hideInstalls() {
      return this.isFreeUser;
    },
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    csvUrl() {
      return '?app_id=' + this.currentApp.id
        + '&country_id_rating=' + this.currentCountry.id
        + '&is_competitor=' + this.isCompetitors
        + this.searchFilterData.generateFilterString()
        + this.searchFilterData.sorting.generateFilterString()
        + '&per_page=60000';
    },
    downloadUrl() {
      if (this.currentApp?.id) {
        return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_COMPETITORS + this.csvUrl;
      }
    },
    filtersData() {
      let filterValueLimits = this.competitorsData.getFilterValueLimits();
      let categories = filterValueLimits.categories_names.map((name) => {
        return {
          presetValue: name,
          filterValue: name,
          selected: true,
        }
      });
      return [
        {
          type: 'dropdown-multi',
          title: 'Category',
          filter: 'categories_names',
          presetsData: categories,
          filtering: 'category'
        },
        {
          type: 'range',
          title: 'Rate',
          filter: 'range_rating',
          min: (0).toFixed(1),
          max: (5).toFixed(1),
          minLimit: 0,
          maxLimit: 5,
          filtering: 'rating'
        },
        {
          type: 'range',
          title: 'Ratings',
          filter: 'range_votes_count',
          min: 0,
          max: 9999999,
          minLimit: 0,
          maxLimit: 99999999,
          filtering: 'votesCount'
        },
      ];
    }
  },
  watch: {
    competitorsCount() {
      this.$emit('competitors-count-changed', this.competitorsCount);
    },
    competitorChangedEventFromModal(value) {
      let competitor = this.competitorsData.competitors.find(item => item.competitorId === value.id);
      competitor.setState(value.type === value.competitorType ? null : value.type);
      this.competitorsCount = this.getLocalCompetitorsNewCount();
    },
    async competitorsData(newValue, oldValue) {
      if (newValue.competitors.length !== oldValue.competitors.length) {
        await this.fetchCompetitorsSummaryImpressions()
      }
    }
  }
}
</script>

<template>
  <div class="competitors-table">
    <div class="progress-wrap" v-if="competitorsTabsLoading">
      <progress-indicator>{{ loaderText }}</progress-indicator>
    </div>
    <template v-else>
      <template v-if="!this.competitorsData.isCompetitorsEmpty()">
        <SimpleInfoTable
          :table-id="'competitors-table'"
          :columns="{
            0: {header: 'Logo', style: {width: '52px', paddingRight: '0'}},
            1: {
              header: 'Application',
              style: {width: '100%', 'min-width': '250px'},
              headerStyle: {paddingLeft: 0},
              orientation: 'left',
              sortBy: 'title',
            },
            2: {header: 'Competitor', orientation: 'left'},
            3: {
              header: 'Publisher',
              headerStyle: {paddingLeft: 0},
              orientation: 'left',
              sortBy: function (item) { return item.developer.title; },
              sortExportParam: 'developer'
            },
            4: {header: 'Category', headerStyle: {paddingLeft: 0}, orientation: 'left', sortBy: 'category'},
            5: {header: 'Rate', orientation: 'right', sortBy: 'rating'},
            6: {header: 'Ratings', sortBy: 'votesCount', sortExportParam: 'votes_count'},
            7: {header: 'Installs', sortBy: 'installs', tooltip: {firstPart:  '<b>Estimated number of app installations</b> in', secondPart: 'over the last month.'}},
            8: {header: 'Revenue', sortBy: 'revenue', tooltip: {firstPart:  '<b>Estimated revenue</b> generated by the app in', secondPart: 'in the last month.'}},
            9: {header: 'Impressions', style: {'min-width': '105px'}, sortBy: 'impressions', tooltip: {firstPart:  '<b>Estimated search impressions</b> over the last 30 days in the', secondPart: ''}},
          }"
          :search-by="['title', 'subTitle', 'category', function (item) { return item.developer.title; }]"
          :defaultSortIndex="6"
          :defaultSortDirection="'desc'"
          :items="competitorsData.getCompetitors()"
          :download-url="downloadUrl"
          :filter-configs="filtersData"
          @refresh-data="refreshData"
        >
          <template v-slot:headers="slotProps">
            <custom-tooltip width="350px" v-if="slotProps.tooltip">
              <template v-slot:slotTrigger>
                <div class="columns-with-flags-container">
                  {{ slotProps.header }}
                  <template v-if="isNeedCountyFlag(slotProps.header)">
                    <div class="columns-with-flags-wrapper">
                      <dynamic-image classes="country-flag"
                                     :width="18"
                                     :height="14"
                                     :size="32"
                                     :country-code="currentCountry.code.toLowerCase()"/>
                    </div>
                  </template>
                </div>
              </template>
              <template v-slot:slotContent>
                <template v-if="isNeedCountyFlag(slotProps.header)">
                  <span v-html="slotProps.tooltip.firstPart"></span>
                  {{ currentCountry.name }}&nbsp;
                  <dynamic-image classes="country-flag"
                                 :width="18"
                                 :height="14"
                                 :size="32"
                                 :country-code="currentCountry.code.toLowerCase()"/>{{slotProps.tooltip.secondPart}}
                </template>
                <template v-else>
                  {{ slotProps.tooltip }}
                </template>
              </template>
            </custom-tooltip>
          </template>
          <template v-slot:items-0="slotProps">
            <div class="cursor-pointer" @click="this.$emit('clicked', slotProps.item)">
              <img v-if="slotProps.item.logo"
                   :width="52"
                   :height="52"
                   :src="cropImage(slotProps.item.logo, '52x52', slotProps.item.store)"
              />
              <div v-else class="empty-logo"></div>
            </div>
          </template>
          <template v-slot:items-1="slotProps">
            <div class="title-block-wrap">
              <div class="title-block">
                <a :href="slotProps.item.storeUrl"
                   class="link"
                   target="_blank">
                  <svg-icon icon="link-icon" class="link-icon"/>
                </a>
                <div class="title cursor-pointer"
                     @click="this.$emit('clicked', slotProps.item)">
                  {{ slotProps.item.title }}
                </div>
              </div>
              <div class="subtitle-block">
                {{ slotProps.item.subTitle }}
              </div>
            </div>
          </template>
          <template v-slot:items-2="slotProps">
            <div class="competitors-block">
              <change-competitor-state :item="{
                                         data: {
                                           id: slotProps.item.competitorId,
                                         },
                                         competitorType: slotProps.item.competitorState,
                                       }"
                                       :current-app-id="currentApp?.id"
                                       @competitor-changed="competitorChanged"
              />
            </div>
          </template>
          <template v-slot:items-3="slotProps">
            <div>
              {{ slotProps.item.developer.title }}
            </div>
          </template>
          <template v-slot:items-4="slotProps">
            <div>
              {{ slotProps.item.category }}
            </div>
          </template>
          <template v-slot:items-5="slotProps">
            <div class="rating-block">
              <div class="rating">
                <div class="css-icons star-icon"></div>
                <strong>{{ slotProps.item.rating }}</strong>
              </div>
            </div>
          </template>
          <template v-slot:items-6="slotProps">
            {{ shortenDigits(slotProps.item.votesCount) }}
          </template>
          <template v-slot:items-7="slotProps">
            <div :class="{blurred: hideInstalls}">
              {{ shortenDigits(slotProps.item.installs) }}
            </div>
          </template>
          <template v-slot:items-8="slotProps">
            <div class="subscription-block" v-if="hideInstalls && slotProps.index === 0">
              <svg-icon icon="locker"/>
              <div class="title">
                More with<br>Pro Plan
              </div>
              <base-button height="40px"
                           width="140px"
                           font-size="16px"
                           border-radius="4px"
                           :mode="'btn-standard'"
                           @click="openPlansModal">
                Go to Plans
              </base-button>
            </div>

            <div :class="{blurred: hideInstalls}">
              {{ shortenDigits(slotProps.item.revenue) }}
            </div>
          </template>
          <template v-slot:items-9="slotProps">
            <div class="display-flex f-align-center f-j-end">
              <template v-if="isSummaryImpressionsLoaded">
                {{ slotProps.item.impressions ? formatNumber(slotProps.item.impressions) : 0 }}
              </template>
              <template v-else>
                <preloader-table-small :loader-size="'25px'"></preloader-table-small>
              </template>
           </div>
          </template>
        </SimpleInfoTable>
      </template>
      <template v-else>
        <div class="empty-competitors-block">
          <img src="@/assets/images/empty-state.svg"
               width="240"
               height="178"
               alt="No competitors image"/>

          <div class="text">
            <strong>No competitors have been selected for the app. </strong>
            Select relevant competitors so that we can match your semantic core with recommended keywords.
          </div>

          <div class="btn-block">
            <router-link :to="{name: 'CompetitorsFinder'}"
                         class="btn btn-default">
              Go to Competitors Finder
            </router-link>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss">
.cursor-pointer:hover {
  cursor: pointer;
}

.blurred {
  filter: blur(3px);
  opacity: 0.5;
}

.subscription-block {
  position: absolute;
  //right: 48px;
  top: calc(50% - 83px);
  z-index: 1000;
  background: var(--neutral-200);
  border-radius: 8px;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: var(--neutral-800);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding: 15px;
  }

  .svg-icons {
    height: 48px;
    width: 48px;
  }
}
</style>
<style lang="scss">
.th_content {
  .columns-with-flags-container {
    display: flex;
  }

  .columns-with-flags-wrapper {
    margin-left: 5px;
    margin-top: 2px
  }
}
</style>